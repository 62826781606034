// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-embedded-article-js": () => import("./../src/templates/embedded/article.js" /* webpackChunkName: "component---src-templates-embedded-article-js" */),
  "component---src-templates-recommendation-js": () => import("./../src/templates/recommendation.js" /* webpackChunkName: "component---src-templates-recommendation-js" */),
  "component---src-templates-embedded-recommendation-js": () => import("./../src/templates/embedded/recommendation.js" /* webpackChunkName: "component---src-templates-embedded-recommendation-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-recommended-js": () => import("./../src/pages/all-recommended.js" /* webpackChunkName: "component---src-pages-all-recommended-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-embedded-v-1-articles-js": () => import("./../src/pages/embedded/v1/articles.js" /* webpackChunkName: "component---src-pages-embedded-v-1-articles-js" */),
  "component---src-pages-embedded-v-1-index-js": () => import("./../src/pages/embedded/v1/index.js" /* webpackChunkName: "component---src-pages-embedded-v-1-index-js" */),
  "component---src-pages-embedded-v-1-recommended-js": () => import("./../src/pages/embedded/v1/recommended.js" /* webpackChunkName: "component---src-pages-embedded-v-1-recommended-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recommended-js": () => import("./../src/pages/recommended.js" /* webpackChunkName: "component---src-pages-recommended-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */)
}

